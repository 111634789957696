<bit-progress
  [size]="size"
  [text]="text"
  [bgColor]="color"
  [showText]="showText"
  [barWidth]="scoreWidth"
></bit-progress>

<div aria-live="polite" class="tw-sr-only">
  {{ "passwordStrengthScore" | i18n: text }}
</div>
