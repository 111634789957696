<app-header></app-header>

<bit-container>
  <p>{{ "reusedPasswordsReportDesc" | i18n }}</p>
  <div *ngIf="!hasLoaded && loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div class="tw-mt-4" *ngIf="hasLoaded">
    <bit-callout type="success" title="{{ 'goodNews' | i18n }}" *ngIf="!ciphers.length">
      {{ "noReusedPasswords" | i18n }}
    </bit-callout>
    <ng-container *ngIf="ciphers.length">
      <bit-callout type="danger" title="{{ 'reusedPasswordsFound' | i18n }}">
        {{ "reusedPasswordsFoundReportDesc" | i18n: (ciphers.length | number) : vaultMsg }}
      </bit-callout>

      <bit-toggle-group
        *ngIf="showFilterToggle && !isAdminConsoleActive"
        [selected]="filterOrgStatus$ | async"
        (selectedChange)="filterOrgToggle($event)"
        [attr.aria-label]="'addAccessFilter' | i18n"
      >
        <ng-container *ngFor="let status of filterStatus">
          <bit-toggle [value]="status">
            {{ getName(status) }}
            <span bitBadge variant="info"> {{ getCount(status) }} </span>
          </bit-toggle>
        </ng-container>
      </bit-toggle-group>

      <bit-table-scroll [dataSource]="dataSource" [rowSize]="75">
        <ng-container header *ngIf="!isAdminConsoleActive">
          <th bitCell></th>
          <th bitCell>{{ "name" | i18n }}</th>
          <th bitCell>{{ "owner" | i18n }}</th>
          <th bitCell class="tw-text-right">{{ "timesReused" | i18n }}</th>
        </ng-container>
        <ng-template bitRowDef let-row>
          <td bitCell>
            <app-vault-icon [cipher]="row"></app-vault-icon>
          </td>
          <td bitCell>
            <ng-container *ngIf="!organization || canManageCipher(row); else cantManage">
              <a
                bitLink
                href="#"
                appStopClick
                (click)="selectCipher(row)"
                title="{{ 'editItemWithName' | i18n: row.name }}"
                >{{ row.name }}</a
              >
            </ng-container>
            <ng-template #cantManage>
              <span>{{ row.name }}</span>
            </ng-template>
            <ng-container *ngIf="!organization && row.organizationId">
              <i
                class="bwi bwi-collection"
                appStopProp
                title="{{ 'shared' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="tw-sr-only">{{ "shared" | i18n }}</span>
            </ng-container>
            <ng-container *ngIf="row.hasAttachments">
              <i
                class="bwi bwi-paperclip"
                appStopProp
                title="{{ 'attachments' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="tw-sr-only">{{ "attachments" | i18n }}</span>
            </ng-container>
            <br />
            <small>{{ row.subTitle }}</small>
          </td>
          <td bitCell>
            <app-org-badge
              *ngIf="!organization"
              [disabled]="disabled"
              [organizationId]="row.organizationId"
              [organizationName]="row.organizationId | orgNameFromId: (organizations$ | async)"
              appStopProp
            >
            </app-org-badge>
          </td>
          <td bitCell class="tw-text-right">
            <span bitBadge variant="warning">
              {{ "reusedXTimes" | i18n: passwordUseMap.get(row.login.password) }}
            </span>
          </td>
        </ng-template>
      </bit-table-scroll>
    </ng-container>
  </div>
  <ng-template #cipherAddEdit></ng-template>
</bit-container>
