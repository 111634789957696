import { svgIcon } from "@bitwarden/components";

export const TwoFactorAuthWebAuthnIcon = svgIcon`
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 100">
    <path class="tw-fill-art-primary"
        d="M19.258 16.434c0-3.635 2.966-6.58 6.626-6.58H56.88c3.659 0 6.625 2.946 6.625 6.58v27.788l-2.208 2.667V16.434c0-2.423-1.978-4.387-4.417-4.387H25.884c-2.44 0-4.418 1.964-4.418 4.387v67.09c0 2.422 1.978 4.386 4.418 4.386h16.389l-.814 2.194H25.884c-3.66 0-6.626-2.947-6.626-6.58v-67.09Z" />
    <path class="tw-fill-art-primary" fill-rule="evenodd"
        d="M40.119 19.287a.55.55 0 0 1 .552-.548h2.208a.55.55 0 0 1 .553.548.55.55 0 0 1-.553.549h-2.208a.55.55 0 0 1-.552-.549Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent" fill-rule="evenodd"
        d="M31.994 38.828c2.103-3.001 5.541-4.957 9.425-4.957 5.66 0 10.376 4.155 11.392 9.653a.567.567 0 0 1-.439.664.553.553 0 0 1-.646-.451c-.918-4.969-5.183-8.73-10.307-8.73-3.515 0-6.625 1.769-8.53 4.485a.542.542 0 0 1-.77.129.579.579 0 0 1-.125-.793Zm-.623 2.186c.282.117.42.448.305.74a10.928 10.928 0 0 0-.748 3.992v3.46a.56.56 0 0 1-.553.569.56.56 0 0 1-.552-.568v-3.461c0-1.56.294-3.051.829-4.417a.547.547 0 0 1 .718-.315Zm21.09 4.164a.56.56 0 0 1 .553.568v3.23a.56.56 0 0 1-.552.568.56.56 0 0 1-.552-.568v-3.23a.56.56 0 0 1 .552-.568Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent" fill-rule="evenodd"
        d="M32.823 41.892c1.451-3.427 4.75-5.827 8.596-5.827 1.98 0 3.819.638 5.333 1.725.25.18.31.532.135.788a.544.544 0 0 1-.769.138 8.02 8.02 0 0 0-4.7-1.52c-3.382 0-6.297 2.111-7.582 5.146a.547.547 0 0 1-.726.294.57.57 0 0 1-.287-.744Zm14.94-2.495a.543.543 0 0 1 .778.066 9.936 9.936 0 0 1 2.265 6.35v7.783a.559.559 0 0 1-.552.566.559.559 0 0 1-.553-.566v-7.782a8.788 8.788 0 0 0-2.002-5.62.575.575 0 0 1 .064-.797Zm-15.06 4.804a.562.562 0 0 1 .487.625 9.062 9.062 0 0 0-.053.988v7.782a.559.559 0 0 1-.553.566.559.559 0 0 1-.552-.566v-7.782c0-.377.02-.748.06-1.114a.555.555 0 0 1 .611-.499Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent" fill-rule="evenodd"
        d="M34.24 45.704c0-4.105 3.207-7.446 7.178-7.446 3.972 0 7.178 3.341 7.178 7.446V55.79a.559.559 0 0 1-.552.565.559.559 0 0 1-.552-.565V45.704c0-3.495-2.726-6.315-6.074-6.315-3.347 0-6.073 2.82-6.073 6.315v3.948a.559.559 0 0 1-.553.566.559.559 0 0 1-.552-.566v-3.948Zm.552 5.572c.305 0 .553.253.553.565v3.949a.559.559 0 0 1-.553.565.559.559 0 0 1-.552-.565V51.84c0-.312.247-.565.552-.565Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent" fill-rule="evenodd"
        d="M38.89 41.142a4.955 4.955 0 0 1 2.529-.69 4.966 4.966 0 0 1 4.97 4.962v3.044a.55.55 0 0 1-.553.548.55.55 0 0 1-.552-.548v-3.044a3.865 3.865 0 0 0-5.83-3.33.554.554 0 0 1-.757-.19.546.546 0 0 1 .192-.752Zm-.911 1.45c.258.16.335.5.173.756a3.844 3.844 0 0 0-.599 2.066v11.49a.55.55 0 0 1-.552.548.55.55 0 0 1-.552-.548v-11.49c0-.974.282-1.884.768-2.651a.554.554 0 0 1 .762-.172Zm7.857 7.904a.55.55 0 0 1 .552.549v5.859a.55.55 0 0 1-.552.548.55.55 0 0 1-.552-.548v-5.86a.55.55 0 0 1 .552-.547Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent" fill-rule="evenodd"
        d="M38.658 45.306c0-1.488 1.256-2.66 2.76-2.66 1.505 0 2.761 1.172 2.761 2.66v4.829a.55.55 0 0 1-.552.548.55.55 0 0 1-.552-.548v-4.828c0-.845-.722-1.564-1.657-1.564-.934 0-1.656.72-1.656 1.563v12.146a.55.55 0 0 1-.552.549.55.55 0 0 1-.552-.549V45.307Zm4.97 6.317a.55.55 0 0 1 .551.549v5.28a.55.55 0 0 1-.552.549.55.55 0 0 1-.552-.549v-5.28a.55.55 0 0 1 .552-.549Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent" fill-rule="evenodd"
        d="M41.419 44.84a.55.55 0 0 1 .552.548v1.9a.55.55 0 0 1-.552.548.55.55 0 0 1-.552-.549v-1.9a.55.55 0 0 1 .552-.548Zm0 4a.55.55 0 0 1 .552.548V58a.55.55 0 0 1-.552.549.55.55 0 0 1-.552-.549v-8.612a.55.55 0 0 1 .552-.549Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-primary" fill-rule="evenodd"
        d="M48.904 87.97c2.492 0 4.542-2.042 4.542-4.616 0-2.51-1.986-4.616-4.542-4.616-2.55 0-4.543 2.049-4.543 4.616 0 2.574 2.05 4.616 4.543 4.616Zm3.016-4.616c0 1.739-1.376 3.086-3.016 3.086-1.64 0-3.017-1.347-3.017-3.086 0-1.745 1.333-3.086 3.017-3.086 1.677 0 3.016 1.385 3.016 3.086Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-primary" fill-rule="evenodd"
        d="M108.913 84.456a.767.767 0 0 0 0-1.074l-6.098-6.213a.76.76 0 0 0-.544-.228H70.225c-2.438-5.616-7.969-9.602-14.417-9.602-8.75 0-15.73 7.185-15.73 16.015 0 8.832 7.032 16.015 15.73 16.015 6.498 0 12.081-4.036 14.475-9.756h8.303c.204 0 .4-.082.544-.228l2.933-2.989 3.034 3.091a.762.762 0 0 0 1.088 0l.918-.934.766.78a.762.762 0 0 0 1.08.008l3.135-3.107 3.294 3.356c.143.146.339.229.543.229h7.408a.76.76 0 0 0 .544-.229l5.04-5.134Zm-5.903 3.832h-6.769l-3.606-3.674a.762.762 0 0 0-1.08-.007l-3.135 3.106-.774-.788a.762.762 0 0 0-1.087 0l-.918.935-3.034-3.091a.762.762 0 0 0-1.088 0l-3.253 3.314h-8.5c-.316 0-.6.196-.712.493-2.06 5.419-7.23 9.263-13.246 9.263-7.832 0-14.204-6.475-14.204-14.485 0-8.011 6.323-14.485 14.204-14.485 5.966 0 11.09 3.795 13.2 9.12a.763.763 0 0 0 .709.482h32.235l5.346 5.448-4.288 4.37Z"
        clip-rule="evenodd" />
    <path class="tw-fill-art-accent"
        d="M100.357 80.991a.382.382 0 0 0-.382-.382H73.651a.382.382 0 0 0 0 .765h26.324c.211 0 .382-.171.382-.383ZM65.693 77.56c-2.04-3.686-5.74-6.133-9.95-6.133-.895 0-1.766.11-2.602.319a.382.382 0 0 1-.184-.743 11.478 11.478 0 0 1 2.785-.341c4.52 0 8.46 2.627 10.618 6.528a14.09 14.09 0 0 1 1.563 4.503.382.382 0 1 1-.752.126 13.324 13.324 0 0 0-1.478-4.258Z" />
    <path class="tw-fill-art-primary tw-stroke-art-primary" fill-rule="evenodd" stroke-width=".355"
        d="M75.94 32.403c8.007 0 14.498 6.51 14.498 14.538a14.542 14.542 0 0 1-9.26 13.56c8.849 1.9 15.896 8.519 18.221 17.036l-1.472.404C95.343 68.475 86.492 61.48 75.95 61.48c-6.743 0-12.796 2.861-16.96 7.404l-1.123-1.036a24.374 24.374 0 0 1 12.842-7.344 14.542 14.542 0 0 1-9.265-13.563c0-8.029 6.49-14.538 14.497-14.538Zm12.972 14.538c0-7.184-5.808-13.007-12.972-13.007S62.97 39.757 62.97 46.94c0 7.184 5.807 13.008 12.971 13.008s12.972-5.823 12.972-13.008Z"
        clip-rule="evenodd" />
</svg>
`;
