<app-header [title]="title" [icon]="icon">
  <bit-breadcrumbs *ngIf="showBreadcrumbs" slot="breadcrumbs">
    <bit-breadcrumb
      *ngIf="activeOrganizationId"
      [route]="[]"
      [queryParams]="{ organizationId: activeOrganizationId, collectionId: All }"
      queryParamsHandling="merge"
    >
      {{ activeOrganizationId | orgNameFromId: organizations }} {{ "vault" | i18n | lowercase }}
    </bit-breadcrumb>
    <ng-container>
      <bit-breadcrumb
        *ngFor="let collection of collections"
        icon="bwi-collection"
        [route]="[]"
        [queryParams]="{ collectionId: collection.id }"
        queryParamsHandling="merge"
      >
        {{ collection.name }}
      </bit-breadcrumb>
    </ng-container>
  </bit-breadcrumbs>

  <ng-container slot="title-suffix">
    <ng-container *ngIf="collection != null && (canEditCollection || canDeleteCollection)">
      <button
        bitIconButton="bwi-angle-down"
        [bitMenuTriggerFor]="editCollectionMenu"
        size="small"
        type="button"
        aria-haspopup="true"
      ></button>
      <bit-menu #editCollectionMenu>
        <button
          type="button"
          *ngIf="canEditCollection"
          bitMenuItem
          (click)="editCollection(CollectionDialogTabType.Info)"
        >
          <i class="bwi bwi-fw bwi-pencil-square" aria-hidden="true"></i>
          {{ "editInfo" | i18n }}
        </button>
        <button
          type="button"
          *ngIf="canEditCollection"
          bitMenuItem
          (click)="editCollection(CollectionDialogTabType.Access)"
        >
          <i class="bwi bwi-fw bwi-users" aria-hidden="true"></i>
          {{ "access" | i18n }}
        </button>
        <button type="button" *ngIf="canDeleteCollection" bitMenuItem (click)="deleteCollection()">
          <span class="tw-text-danger">
            <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>
            {{ "delete" | i18n }}
          </span>
        </button>
      </bit-menu>
    </ng-container>
    <small *ngIf="loading">
      <i
        class="bwi bwi-spinner bwi-spin tw-text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="tw-sr-only">{{ "loading" | i18n }}</span>
    </small>
  </ng-container>

  <div *ngIf="filter.type !== 'trash'" class="tw-shrink-0">
    <div appListDropdown>
      <button
        bitButton
        buttonType="primary"
        type="button"
        [bitMenuTriggerFor]="addOptions"
        id="newItemDropdown"
        appA11yTitle="{{ 'new' | i18n }}"
      >
        <i class="bwi bwi-plus-f" aria-hidden="true"></i>
        {{ "new" | i18n }}<i class="bwi tw-ml-2" aria-hidden="true"></i>
      </button>
      <bit-menu #addOptions aria-labelledby="newItemDropdown">
        <button type="button" bitMenuItem (click)="addCipher(CipherType.Login)">
          <i class="bwi bwi-globe" slot="start" aria-hidden="true"></i>
          {{ "typeLogin" | i18n }}
        </button>
        <button type="button" bitMenuItem (click)="addCipher(CipherType.Card)">
          <i class="bwi bwi-credit-card" slot="start" aria-hidden="true"></i>
          {{ "typeCard" | i18n }}
        </button>
        <button type="button" bitMenuItem (click)="addCipher(CipherType.Identity)">
          <i class="bwi bwi-id-card" slot="start" aria-hidden="true"></i>
          {{ "typeIdentity" | i18n }}
        </button>
        <button type="button" bitMenuItem (click)="addCipher(CipherType.SecureNote)">
          <i class="bwi bwi-sticky-note" slot="start" aria-hidden="true"></i>
          {{ "note" | i18n }}
        </button>
        <button type="button" bitMenuItem (click)="addCipher(CipherType.SshKey)">
          <i class="bwi bwi-key" slot="start" aria-hidden="true"></i>
          {{ "typeSshKey" | i18n }}
        </button>
        <bit-menu-divider />
        <button type="button" bitMenuItem (click)="addFolder()">
          <i class="bwi bwi-fw bwi-folder" aria-hidden="true"></i>
          {{ "folder" | i18n }}
        </button>
        <button *ngIf="canCreateCollections" type="button" bitMenuItem (click)="addCollection()">
          <i class="bwi bwi-fw bwi-collection" aria-hidden="true"></i>
          {{ "collection" | i18n }}
        </button>
      </bit-menu>
    </div>
  </div>
</app-header>
