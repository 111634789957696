<app-header></app-header>

<bit-container>
  <p>{{ "newOrganizationDesc" | i18n }}</p>
  <app-organization-plans
    [enableSecretsManagerByDefault]="secretsManager"
    [plan]="plan"
    [productTier]="productTier"
  ></app-organization-plans>
</bit-container>
