<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog>
    <span bitDialogTitle>
      {{ "confirmVaultImport" | i18n }}
    </span>

    <div bitDialogContent>
      {{ "confirmVaultImportDesc" | i18n }}
      <bit-form-field class="tw-mt-6">
        <bit-label>{{ "confirmFilePassword" | i18n }}</bit-label>
        <input
          bitInput
          type="password"
          formControlName="filePassword"
          appAutofocus
          appInputVerbatim
        />
        <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
      </bit-form-field>
    </div>

    <ng-container bitDialogFooter>
      <button bitButton buttonType="primary" type="submit">
        <span>{{ "importData" | i18n }}</span>
      </button>
      <button bitButton bitDialogClose buttonType="secondary" type="button">
        <span>{{ "cancel" | i18n }}</span>
      </button>
    </ng-container>
  </bit-dialog>
</form>
