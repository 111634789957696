<app-header></app-header>

<bit-container>
  <ng-container *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
  <bit-table *ngIf="!loading">
    <ng-template body>
      <tr bitRow *ngFor="let p of policies">
        <td bitCell *ngIf="p.display(organization)" ngPreserveWhitespaces>
          <button type="button" bitLink (click)="edit(p)">{{ p.name | i18n }}</button>
          <span bitBadge variant="success" *ngIf="policiesEnabledMap.get(p.type)">{{
            "on" | i18n
          }}</span>
          <small class="tw-text-muted tw-block">{{ p.description | i18n }}</small>
        </td>
      </tr>
    </ng-template>
  </bit-table>
  <ng-template #editTemplate></ng-template>
</bit-container>
