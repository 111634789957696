<div *ngIf="history && history.length">
  <bit-item *ngFor="let h of history">
    <div class="tw-pl-3 tw-py-2">
      <bit-color-password
        class="tw-text-base"
        [password]="h.password"
        [showCount]="false"
      ></bit-color-password>
      <div class="tw-text-sm tw-text-muted">{{ h.lastUsedDate | date: "medium" }}</div>
    </div>
    <ng-container slot="end">
      <bit-item-action>
        <button
          type="button"
          bitIconButton="bwi-clone"
          [appA11yTitle]="'copyPassword' | i18n"
          appStopClick
          [appCopyClick]="h.password"
          [valueLabel]="'password' | i18n"
          showToast
        ></button>
      </bit-item-action>
    </ng-container>
  </bit-item>
</div>
<div
  class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-text-center"
  *ngIf="!history?.length"
>
  <p>{{ "noPasswordsInList" | i18n }}</p>
</div>
