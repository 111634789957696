<form [formGroup]="formGroup" [bitSubmit]="submit">
  <p class="tw-text-center" bitTypography="body1">
    {{ "newDeviceVerificationNoticeContentPage1" | i18n }}
    <a bitLink (click)="navigateToNewDeviceVerificationHelp($event)" href="#">
      {{ "learnMore" | i18n }}.
    </a>
  </p>

  <bit-card
    class="tw-pb-0"
    [ngClass]="{
      'tw-flex tw-flex-col tw-items-center !tw-rounded-b-none': isDesktop,
      'md:tw-flex md:tw-flex-col md:tw-items-center md:!tw-rounded-b-none': !isDesktop,
    }"
  >
    <p bitTypography="body2" class="tw-text-muted md:tw-w-9/12">
      {{ "newDeviceVerificationNoticePageOneFormContent" | i18n: this.currentEmail }}
    </p>

    <bit-radio-group formControlName="hasEmailAccess" class="md:tw-w-9/12">
      <bit-radio-button id="option_A" [value]="0">
        <bit-label>{{ "newDeviceVerificationNoticePageOneEmailAccessNo" | i18n }}</bit-label>
      </bit-radio-button>
      <bit-radio-button id="option_B" [value]="1">
        <bit-label>{{ "newDeviceVerificationNoticePageOneEmailAccessYes" | i18n }}</bit-label>
      </bit-radio-button>
    </bit-radio-group>
  </bit-card>

  <button bitButton type="submit" buttonType="primary" class="tw-w-full tw-mt-4">
    {{ "continue" | i18n }}
  </button>
</form>
