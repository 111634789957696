<router-outlet></router-outlet>

<footer class="tw-text-center tw-my-12">
  <environment-selector></environment-selector>

  <div bitTypography="body2">Vaultwarden Web</div>
  <div bitTypography="body2">{{ version }}</div>
  <br />
  <div bitTypography="helper" id="vw-disclaimer">
    A modified version of the Bitwarden&reg; Web Vault for Vaultwarden (an unofficial rewrite of the
    Bitwarden&reg; server).<br />
    Vaultwarden is not associated with the Bitwarden&reg; project nor Bitwarden Inc.
  </div>
</footer>
