<div class="tw-mt-10 tw-flex tw-justify-center" *ngIf="loading">
  <div>
    <bit-icon class="tw-w-72 tw-block tw-mb-4" [icon]="logo"></bit-icon>
    <div class="tw-flex tw-justify-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x tw-text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="tw-sr-only">{{ "loading" | i18n }}</span>
    </div>
  </div>
</div>
