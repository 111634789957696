<app-header></app-header>

<bit-container>
  <p>{{ "exposedPasswordsReportDesc" | i18n }}</p>
  <button type="submit" buttonType="primary" bitButton [loading]="loading" (click)="load()">
    {{ "checkExposedPasswords" | i18n }}
  </button>
  <div class="tw-mt-4" *ngIf="hasLoaded">
    <bit-callout type="success" title="{{ 'goodNews' | i18n }}" *ngIf="!ciphers.length">
      {{ "noExposedPasswords" | i18n }}
    </bit-callout>
    <ng-container *ngIf="ciphers.length">
      <bit-callout type="danger" title="{{ 'exposedPasswordsFound' | i18n }}" [useAlertRole]="true">
        {{ "exposedPasswordsFoundReportDesc" | i18n: (ciphers.length | number) : vaultMsg }}
      </bit-callout>
      <bit-toggle-group
        *ngIf="showFilterToggle && !isAdminConsoleActive"
        [selected]="filterOrgStatus$ | async"
        (selectedChange)="filterOrgToggle($event)"
        [attr.aria-label]="'addAccessFilter' | i18n"
      >
        <ng-container *ngFor="let status of filterStatus">
          <bit-toggle [value]="status">
            {{ getName(status) }}
            <span bitBadge variant="info"> {{ getCount(status) }} </span>
          </bit-toggle>
        </ng-container>
      </bit-toggle-group>
      <bit-table-scroll [dataSource]="dataSource" [rowSize]="53">
        <ng-container header>
          <th bitCell></th>
          <th bitCell bitSortable="name">{{ "name" | i18n }}</th>
          <th bitCell bitSortable="organizationId" *ngIf="!isAdminConsoleActive">
            {{ "owner" | i18n }}
          </th>
          <th bitCell class="tw-text-right" bitSortable="exposedXTimes">
            {{ "timesExposed" | i18n }}
          </th>
        </ng-container>
        <ng-template bitRowDef let-row>
          <td bitCell>
            <app-vault-icon [cipher]="row"></app-vault-icon>
          </td>
          <td bitCell>
            <ng-container *ngIf="!organization || canManageCipher(row); else cantManage">
              <a
                bitLink
                href="#"
                appStopClick
                (click)="selectCipher(row)"
                title="{{ 'editItemWithName' | i18n: row.name }}"
              >
                {{ row.name }}
              </a>
            </ng-container>
            <ng-template #cantManage>
              <span>{{ row.name }}</span>
            </ng-template>
            <ng-container *ngIf="!organization && row.organizationId">
              <i
                class="bwi bwi-collection"
                appStopProp
                title="{{ 'shared' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="tw-sr-only">{{ "shared" | i18n }}</span>
            </ng-container>
            <ng-container *ngIf="row.hasAttachments">
              <i
                class="bwi bwi-paperclip"
                appStopProp
                title="{{ 'attachments' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="tw-sr-only">{{ "attachments" | i18n }}</span>
            </ng-container>
            <br />
            <small>{{ row.subTitle }}</small>
          </td>
          <td bitCell *ngIf="!isAdminConsoleActive">
            <app-org-badge
              *ngIf="!organization"
              [disabled]="disabled"
              [organizationId]="row.organizationId"
              [organizationName]="row.organizationId | orgNameFromId: (organizations$ | async)"
              appStopProp
            >
            </app-org-badge>
          </td>
          <td bitCell class="tw-text-right">
            <span bitBadge variant="warning">
              {{ "exposedXTimes" | i18n: (row.exposedXTimes | number) }}
            </span>
          </td>
        </ng-template>
      </bit-table-scroll>
    </ng-container>
  </div>
  <ng-template #cipherAddEdit></ng-template>
</bit-container>
