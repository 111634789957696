<ng-template #defaultContent>
  <ng-content></ng-content>
</ng-template>

<div class="tw-relative tw-mt-2">
  <bit-label
    [attr.for]="for"
    class="tw-absolute tw-bg-background tw-px-1 tw-text-sm tw-text-muted -tw-top-2.5 tw-left-3 tw-mb-0 tw-max-w-full tw-pointer-events-auto"
  >
    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    <span class="tw-text-xs tw-font-normal">({{ "required" | i18n }})</span>
  </bit-label>
</div>
