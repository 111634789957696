<bit-dialog>
  <span bitDialogTitle>
    {{ "importError" | i18n }}
  </span>

  <span bitDialogContent>
    <div>{{ "resolveTheErrorsBelowAndTryAgain" | i18n }}</div>
    <bit-table [dataSource]="dataSource">
      <ng-container header>
        <tr>
          <th bitCell>{{ "name" | i18n }}</th>
          <th bitCell>{{ "description" | i18n }}</th>
        </tr>
      </ng-container>
      <ng-template body let-rows$>
        <tr bitRow *ngFor="let r of rows$ | async">
          <td bitCell>{{ r.type }}</td>
          <td bitCell>{{ r.message }}</td>
        </tr>
      </ng-template>
    </bit-table>
  </span>

  <div bitDialogFooter>
    <button bitButton bitDialogClose buttonType="primary" type="button">
      {{ "ok" | i18n }}
    </button>
  </div>
</bit-dialog>
