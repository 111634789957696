<td bitCell [ngClass]="RowHeightClass" class="tw-min-w-fit">
  <input
    type="checkbox"
    bitCheckbox
    appStopProp
    *ngIf="showCheckbox"
    [disabled]="disabled"
    [checked]="checked"
    (change)="$event ? this.checkedToggled.next() : null"
    [attr.aria-label]="'collectionItemSelect' | i18n"
  />
</td>
<td bitCell [ngClass]="RowHeightClass" class="tw-min-w-fit">
  <div aria-hidden="true">
    <i class="bwi bwi-fw bwi-lg bwi-collection"></i>
  </div>
</td>
<td bitCell [ngClass]="RowHeightClass">
  <button
    bitLink
    [disabled]="disabled"
    type="button"
    class="tw-flex tw-w-full tw-text-start tw-leading-snug"
    linkType="secondary"
    title="{{ 'viewCollectionWithName' | i18n: collection.name }}"
    [routerLink]="[]"
    [queryParams]="{ collectionId: collection.id }"
    queryParamsHandling="merge"
    appStopProp
  >
    <span class="tw-truncate tw-mr-1">{{ collection.name }}</span>
    <div>
      <span *ngIf="showAddAccess" bitBadge variant="warning">{{ "addAccess" | i18n }}</span>
    </div>
  </button>
</td>
<td bitCell [ngClass]="RowHeightClass" *ngIf="showOwner" class="tw-hidden lg:tw-table-cell">
  <app-org-badge
    [disabled]="disabled"
    [organizationId]="collection.organizationId"
    [organizationName]="collection.organizationId | orgNameFromId: organizations"
    appStopProp
  >
  </app-org-badge>
</td>
<td bitCell [ngClass]="RowHeightClass" *ngIf="showCollections"></td>
<td bitCell [ngClass]="RowHeightClass" *ngIf="showGroups">
  <app-group-badge
    *ngIf="collectionGroups"
    [selectedGroups]="collectionGroups"
    [allGroups]="groups"
  ></app-group-badge>
</td>
<td bitCell [ngClass]="RowHeightClass" *ngIf="showPermissionsColumn">
  <p class="tw-mb-0 tw-text-muted" [title]="permissionTooltip">
    {{ permissionText }}
  </p>
</td>
<td bitCell [ngClass]="RowHeightClass" class="tw-text-right">
  <button
    *ngIf="canEditCollection || canDeleteCollection || canViewCollectionInfo"
    [disabled]="disabled"
    [bitMenuTriggerFor]="collectionOptions"
    size="small"
    bitIconButton="bwi-ellipsis-v"
    type="button"
    appA11yTitle="{{ 'options' | i18n }}"
    appStopProp
  ></button>
  <bit-menu #collectionOptions>
    <ng-container *ngIf="canEditCollection">
      <button type="button" bitMenuItem (click)="edit(false)">
        <i class="bwi bwi-fw bwi-pencil-square" aria-hidden="true"></i>
        {{ "editInfo" | i18n }}
      </button>
      <button type="button" bitMenuItem (click)="access(false)">
        <i class="bwi bwi-fw bwi-users" aria-hidden="true"></i>
        {{ "editAccess" | i18n }}
      </button>
    </ng-container>
    <ng-container *ngIf="!canEditCollection && canViewCollectionInfo">
      <button type="button" bitMenuItem (click)="edit(true)">
        <i class="bwi bwi-fw bwi-pencil-square" aria-hidden="true"></i>
        {{ "viewInfo" | i18n }}
      </button>
      <button type="button" bitMenuItem (click)="access(true)">
        <i class="bwi bwi-fw bwi-users" aria-hidden="true"></i>
        {{ "viewAccess" | i18n }}
      </button>
    </ng-container>
    <button *ngIf="canDeleteCollection" type="button" bitMenuItem (click)="deleteCollection()">
      <span class="tw-text-danger">
        <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>
        {{ "delete" | i18n }}
      </span>
    </button>
  </bit-menu>
</td>
