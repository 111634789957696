<span class="tw-relative">
  <span [ngClass]="{ 'tw-invisible': showLoadingStyle() }">
    <ng-content></ng-content>
  </span>
  <span
    class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
    [ngClass]="{ 'tw-invisible': !showLoadingStyle() }"
  >
    <i class="bwi bwi-spinner bwi-lg bwi-spin" aria-hidden="true"></i>
  </span>
</span>
