<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog>
    <span bitDialogTitle>
      {{ "lastPassAuthRequired" | i18n }}
    </span>

    <div bitDialogContent>
      <bit-form-field class="!tw-mb-0">
        <bit-label>{{ "lastPassMasterPassword" | i18n }}</bit-label>
        <input bitInput type="password" formControlName="password" appAutofocus appInputVerbatim />
        <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
        <bit-hint>{{ "confirmIdentity" | i18n }}</bit-hint>
      </bit-form-field>
    </div>

    <ng-container bitDialogFooter>
      <button bitButton buttonType="primary" type="submit" bitFormButton>
        <span>{{ "submit" | i18n }}</span>
      </button>
      <button bitButton bitDialogClose buttonType="secondary" type="button" bitFormButton>
        <span>{{ "cancel" | i18n }}</span>
      </button>
    </ng-container>
  </bit-dialog>
</form>
