<div
  class="tw-my-4 tw-flex tw-max-h-screen tw-w-96 tw-max-w-90vw tw-flex-col tw-overflow-hidden tw-rounded-3xl tw-border tw-border-solid tw-border-secondary-300 tw-bg-text-contrast tw-text-main"
  @fadeIn
>
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-px-4 tw-pt-4 tw-text-center">
    @if (!hideIcon()) {
      @if (hasIcon) {
        <ng-content select="[bitDialogIcon]"></ng-content>
      } @else {
        <i class="bwi bwi-exclamation-triangle tw-text-3xl tw-text-warning" aria-hidden="true"></i>
      }
    }
    <h1
      bitDialogTitleContainer
      bitTypography="h3"
      noMargin
      class="tw-w-full tw-text-main tw-break-words tw-hyphens-auto"
    >
      <ng-content select="[bitDialogTitle]"></ng-content>
    </h1>
  </div>
  <div
    class="tw-overflow-y-auto tw-px-4 tw-pb-4 tw-text-center tw-text-base tw-break-words tw-hyphens-auto"
  >
    <ng-content select="[bitDialogContent]"></ng-content>
  </div>
  <div
    class="tw-flex tw-flex-row tw-gap-2 tw-border-0 tw-border-t tw-border-solid tw-border-secondary-300 tw-p-4"
  >
    <ng-content select="[bitDialogFooter]"></ng-content>
  </div>
</div>
