<div [formGroup]="data">
  <bit-form-control>
    <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
    <bit-label>{{ "turnOn" | i18n }}</bit-label>
  </bit-form-control>

  <div class="tw-grid tw-grid-cols-12 tw-gap-4">
    <bit-form-field class="tw-col-span-6 tw-mb-0">
      <bit-label>{{ "overridePasswordTypePolicy" | i18n }}</bit-label>
      <bit-select formControlName="overridePasswordType" id="overrideType">
        <bit-option
          *ngFor="let o of overridePasswordTypeOptions"
          [value]="o.value"
          [label]="o.name"
        ></bit-option>
      </bit-select>
    </bit-form-field>
  </div>

  <!-- password-specific policies -->
  <div *ngIf="showPasswordPolicies$ | async">
    <h3 bitTypography="h3" class="tw-mt-4">{{ "password" | i18n }}</h3>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4">
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "minLength" | i18n }}</bit-label>
        <input
          bitInput
          type="number"
          [min]="minLengthMin"
          [max]="minLengthMax"
          formControlName="minLength"
        />
      </bit-form-field>
    </div>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4">
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "minNumbers" | i18n }}</bit-label>
        <input
          bitInput
          type="number"
          [min]="minNumbersMin"
          [max]="minNumbersMax"
          formControlName="minNumbers"
        />
      </bit-form-field>
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "minSpecial" | i18n }}</bit-label>
        <input
          bitInput
          type="number"
          [min]="minSpecialMin"
          [max]="minSpecialMax"
          formControlName="minSpecial"
        />
      </bit-form-field>
    </div>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="useUpper" id="useUpper" />
      <bit-label>{{ "uppercaseLabel" | i18n }}</bit-label>
    </bit-form-control>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="useLower" id="useLower" />
      <bit-label>{{ "lowercaseLabel" | i18n }}</bit-label>
    </bit-form-control>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="useNumbers" id="useNumbers" />
      <bit-label>{{ "numbersLabel" | i18n }}</bit-label>
    </bit-form-control>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="useSpecial" id="useSpecial" />
      <!-- hard-coded the special characters string because `$` indicates an i18n interpolation,
           and is handled inconsistently across browsers. Angular template syntax is used to
           ensure special characters are entity-encoded.
        -->
      <bit-label>{{ "!@#$%^&*" }}</bit-label>
    </bit-form-control>
  </div>

  <!-- passphrase-specific policies -->
  <div *ngIf="showPassphrasePolicies$ | async">
    <h3 bitTypography="h3" class="tw-mt-4">{{ "passphrase" | i18n }}</h3>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4">
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "minimumNumberOfWords" | i18n }}</bit-label>
        <input
          bitInput
          type="number"
          [min]="minNumberWordsMin"
          [max]="minNumberWordsMax"
          formControlName="minNumberWords"
        />
      </bit-form-field>
    </div>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="capitalize" id="capitalize" />
      <bit-label>{{ "capitalize" | i18n }}</bit-label>
    </bit-form-control>
    <bit-form-control>
      <input type="checkbox" bitCheckbox formControlName="includeNumber" id="includeNumber" />
      <bit-label>{{ "includeNumber" | i18n }}</bit-label>
    </bit-form-control>
  </div>
</div>
